import * as React from "react";
import { navigate } from "gatsby";
import { Location } from "@reach/router";

import githubLogo from "../images/github.png";
import * as styles from "./Layout.module.css";

export const Layout = ({ children }: React.PropsWithChildren) => (
  <Location>
    {({ location }) => (
      <div className={styles.container}>
        <div className={styles.top}>
          <h1
            className={location.pathname !== "/" ? styles.clickable : ""}
            onClick={
              location.pathname !== "/" ? () => navigate("/") : undefined
            }
          >
            Thomas Forte
          </h1>
        </div>

        <div className={styles.middle}>{children}</div>

        <div className={styles.bottom}>
          <span>
            &copy;<a href="https://github.com/thomas-forte">Thomas Forte</a>
            {new Date().getFullYear()}
          </span>
          <span className={styles.viewGithub}>
            - View on
            <a href="https://github.com/thomas-forte">
              <span>Github</span>
              <img src={githubLogo} alt="github" />
            </a>
          </span>
        </div>
      </div>
    )}
  </Location>
);
