/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

import "./src/styles.css";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet";

import { Layout } from "./src/components/Layout";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => <Layout>{element}</Layout>;
